import React, { useState, useEffect } from 'react'

const config = require('./config.json')

function RightPanel (props) {
  const {
    observations,
    featureProperties,
    getObservations,
    recordObservation
  } = props

  const [observation, setObservation] = useState('')

  const submitObservation = async () => {
    setObservation('')
    await recordObservation(featureProperties.id, observation)
    getObservations()
  }

  const changeObservation = e => {
    setObservation(e.target.value)
  }

  useEffect(() => {
    if (featureProperties && featureProperties.systemID) getObservations()
  }, [featureProperties, getObservations])

  return (
    <div className='osel-sliding-side-panel panel-right'>
      <div className='panel-close'>
        <i className='material-icons' aria-label='Close panel'>
          close
        </i>
      </div>
      <div className='panel-header'>Properties</div>
      <div className='feature-header'>
        <div className='layer-name'></div>
      </div>
      <div className='scroller-no-tabs'>
        <div className='osel-feature-properties'>
          {Object.keys(featureProperties).map((key, idx) => {
            if (config.excludePropertyFields.includes(key)) return null
            return (
              <div class='property' key={idx}>
                <div>{key}</div>
                <div>{featureProperties[key]}</div>
              </div>
            )
          })}
          {observations.map((observation, idx) => {
            return (
              <div class='property' key={idx}>
                <div>{observation.properties.observation}</div>
                <div>{observation.properties.created_at}</div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='panel-footer'>
        <div>
          <label>Observation </label>
          <br />
          <textarea
            className='textarea'
            value={observation}
            onChange={changeObservation}
            rows={5}
            cols={5}
          />
        </div>
        <div className='panel-footer-buttons'>
          <button
            className='osel-button osel-primary-button'
            onClick={submitObservation}
          >
            Submit observation
          </button>
        </div>
      </div>
    </div>
  )
}

export default RightPanel
