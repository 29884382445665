const config = require('./../config.json')

export function getToken() {
  return localStorage.getItem('token')
}

export function saveTokenAndUsername(token, username) {
  localStorage.setItem('token', token)
  localStorage.setItem('username', username)
}

export function getUsername() {
  return localStorage.getItem('username')
}

export function removeTokenAndUsername() {
  localStorage.removeItem('token')
  localStorage.removeItem('username')
}

export const login = async (username, password) => {
  try {
    const response = await fetch(
      `${config.login}?username=${username}&password=${password}`
    )
    const result = await response.text()
    if (result && result !== '') return result
  } catch {}
  return null
}
