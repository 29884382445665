import React, { useCallback, useEffect, useState } from 'react'

// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from '!maplibre-gl'

import ReactMapGl, {
  NavigationControl,
  Source,
  Layer
} from 'react-map-gl/maplibre'

import DrawControl, { drawRef } from './DrawControl'

import {
  scrollSidePanelUp,
  openRightPanel,
  closeRightPanel
} from './helpers/osel'

import 'maplibre-gl/dist/maplibre-gl.css'

import * as geoHelper from './helpers/geo'

const config = require('./config.json')

export default function Map (props) {
  const {
    viewState,
    setViewState,
    mapRef,
    mapData,
    polygon,
    setDrawnPolygon,
    mapLayers,
    setFeatureProperties,
    setDrawRef
  } = props

  const [polygonMask, setPolygonMask] = useState(null)

  useEffect(() => {
    setDrawRef(drawRef)
  }, [setDrawRef])

  const onUpdateOrDeleteDraw = useCallback(
    e => {
      const getFeatures = e => {
        const newFeatures = { ...polygon }
        for (const f of e.features) {
          newFeatures[f.id] = f
        }
        return newFeatures
      }

      // get the first property in the returned object
      const drawnPolygon = getFeatures(e)[Object.keys(getFeatures(e))[0]]
      setDrawnPolygon(drawnPolygon)

      const mask = geoHelper.getMaskFromGeoJson(drawnPolygon)
      setPolygonMask(mask)

      // Clear the draw component
      drawRef.deleteAll()
    },
    [polygon, setDrawnPolygon]
  )

  const showAttributes = properties => {
    setFeatureProperties(properties)
    scrollSidePanelUp()
    openRightPanel()
  }

  const clickMap = e => {
    var bbox = [
      [e.point.x - 2.5, e.point.y - 2.5],
      [e.point.x + 2.5, e.point.y + 2.5]
    ]

    // Check if the layer is currently on the map
    var queryLayers = mapLayers.filter(layer => {
      return mapRef.current.getLayer(layer)
    })
    var features = mapRef.current.queryRenderedFeatures(bbox, {
      layers: queryLayers
    })

    if (!features.length) {
      closeRightPanel()
      return
    }

    showAttributes(features[0].properties)
  }

  return (
    <ReactMapGl
      ref={mapRef}
      mapLib={maplibregl}
      onMove={evt => setViewState(evt.viewState)}
      style={{ width: '100%', height: ' calc(100vh - 64px)' }}
      mapStyle='/OS_VTS_3857_Light_UseMap.json'
      transformRequest={url => {
        if (!url.includes('key=')) {
          url += `?key=${config.tileKey}`
        }
        return {
          url: url + '&srs=3857'
        }
      }}
      onClick={clickMap}
      {...viewState}
    >
      {Object.keys(config.dataSources).map((key, index) => {
        const source = config.dataSources[key]
        if (mapData[key] === undefined) return null
        if (mapLayers.indexOf(key) === -1) return null
        return (
          <Source key={index} id={key} type={source.type} data={mapData[key]}>
            {source.layers.map((layer, index) => {
              return <Layer key={index} {...layer} />
            })}
          </Source>
        )
      })}
      {polygonMask && (
        <Source id='polygon_mask' type='geojson' data={polygonMask}>
          <Layer
            id='polygon_mask_fill'
            type='fill'
            paint={{
              'fill-color': '#000',
              'fill-opacity': 0.3
            }}
          />
          <Layer
            id='outline'
            type='line'
            paint={{
              'line-color': '#ccc',
              'line-width': 1
            }}
          />
        </Source>
      )}
      <NavigationControl position='bottom-right' />
      <DrawControl
        position='top-right'
        onCreate={onUpdateOrDeleteDraw}
        onUpdate={onUpdateOrDeleteDraw}
        onDelete={onUpdateOrDeleteDraw}
      />
    </ReactMapGl>
  )
}
