import React, { useEffect, useState, useRef } from 'react'

import Script from 'react-load-script'

import LeftPanel from './LeftPanel.js'
import Login from './Login.js'
import Map from './Map.js'
import Navbar from './Navbar.js'
import RightPanel from './RightPanel.js'

import './App.css'

import { getMapData } from './helpers/geo.js'
import {
  getToken,
  getUsername,
  saveTokenAndUsername,
  removeTokenAndUsername
} from './models/user.js'
import {
  getFeatureObservations,
  addFeatureObservation
} from './models/observation.js'

function App () {
  // Login token
  const [userToken, setUserToken] = useState(null)
  const [username, setUsername] = useState('')

  // Map state
  const mapRef = useRef(null)
  const [viewState, setViewState] = useState({
    longitude: 0.033010,
    latitude: 52.112346,
    zoom: 14
  })
  const [polygon, setPolygon] = useState({})
  const [mapLayers, setMapLayers] = useState([])
  const [mapData, setMapData] = useState({})
  const [drawRef, setDrawRef] = useState({}) // eslint-disable-line no-unused-vars

  // Currently selected feature properties
  const [featureProperties, setFeatureProperties] = useState([])

  // Observation data
  const [observations, setObservations] = useState([])

  useEffect(() => {
    const token = getToken()
    if (token !== null) {
      const username = getUsername()
      setUserToken(token)
      setUsername(username)
    }
  }, [])

  const storeLogin = (token, username) => {
    saveTokenAndUsername(token, username)
    window.location.reload(false)
  }

  const refreshMapData = async polygon => {
    // Clear the map data
    setMapData({})
    const mapData = await getMapData(polygon)
    setMapData(mapData)
  }

  const setDrawnPolygon = polygon => {
    setPolygon(polygon)
    refreshMapData(polygon)
  }

  const changeMapLocation = (lng, lat, zoom) => {
    setViewState({ longitude: lng, latitude: lat, zoom: zoom })
  }

  const getObservations = async id => {
    const observations = await getFeatureObservations(id)
    if (observations) setObservations(observations)
  }

  const recordObservation = async (id, observation) => {
    await addFeatureObservation(id, observation)
  }

  const deleteToken = () => {
    removeTokenAndUsername()
    setUserToken(null)
    setUsername('')
  }

  return (
    <div className='App'>
      {userToken === null ? ( // If not logged in display the login page
        <>
          <Login storeLogin={storeLogin} />
        </>
      ) : (
        <>
          <Navbar
            changeMapLocation={changeMapLocation}
            deleteToken={deleteToken}
            username={username}
          />
          <div className='content'>
            <LeftPanel mapData={mapData} setMapLayers={setMapLayers} />
            <Map
              viewState={viewState}
              setViewState={setViewState}
              mapRef={mapRef}
              polygon={polygon}
              setDrawnPolygon={setDrawnPolygon}
              mapLayers={mapLayers}
              mapData={mapData}
              setFeatureProperties={setFeatureProperties}
              drawRef={drawRef}
              setDrawRef={setDrawRef}
            />
            <RightPanel
              featureProperties={featureProperties}
              observations={observations}
              getObservations={getObservations}
              recordObservation={recordObservation}
            />
          </div>
          <Script url='/assets/js/osel-framework-v1.2.1.min.js' />
        </>
      )}
    </div>
  )
}

export default App
