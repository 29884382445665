import React, { useEffect } from 'react'

import * as oselHelpers from './helpers/osel'

const config = require('./config.json')

function LeftPanel (props) {
  const { mapData, setMapLayers } = props

  // Runs on component mount to set up the toggleLayer and filterLayer functions
  useEffect(() => {
    oselHelpers.addToggleLayerFunction(() => {
      oselHelpers.closeRightPanel()
      const layerIds = oselHelpers.getCurrentLayerIds()
      setMapLayers(layerIds)
    })

    oselHelpers.addFilterLayerFunction(() => {
      oselHelpers.closeRightPanel()
    })
    const layerIds = oselHelpers.getCurrentLayerIds()
    setMapLayers(layerIds)
  }, [setMapLayers])

  return (
    <div className='osel-sliding-side-panel panel-left'>
      <div className='panel-toggle'>
        <div className='toggle-handle' aria-label='Toggle panel'></div>
      </div>
      <div className='panel-content'>
        <div className='panel-header'>Layers</div>
        <div className='scroller-no-tabs'>
          <ul className='layers'>
            {Object.keys(config.dataSources).map((sourceId, index) => {
              const source = config.dataSources[sourceId]
              const validGeometries =
                mapData[sourceId] &&
                mapData[sourceId].features &&
                mapData[sourceId].features.filter(
                  feature => feature.geometry !== null
                ).length > 0
              return (
                <li
                  key={`lyr_${index}`}
                  className='layer'
                  data-layer-id={sourceId}
                >
                  <div
                    className={
                      mapData[sourceId] && validGeometries
                        ? 'layer-element'
                        : 'layer-element disabled'
                    }
                    data-type='checkbox'
                    data-state='checked'
                    disabled
                  >
                    <span className='checkmark'></span>
                    <div
                      className='key'
                      style={{ backgroundColor: source.colour }}
                    ></div>
                    <div className='label-klx0'>{source.title}</div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default LeftPanel
