import React, { useState } from 'react'

import * as user from './models/user'

function Login (props) {
  const { storeLogin } = props
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const login = async () => {
    const token = await user.login(username, password)
    if (token) storeLogin(token, username)
  }

  return (
    <div className='osel-modal-dialogue'>
      <div className='osel-modal-dialogue-body'>
        <div className='osel-modal-dialogue-title'>Secure data sharing</div>
        <div className='osel-modal-dialogue-content'>
          <div className='osel-logo'>
            <img src='../assets/img/os-logo.svg' alt='Ordnance Survey logo' />
          </div>
          <div className='form-input'>
            <label>Username</label>
            <br />
            <input
              className='textarea'
              type='text'
              placeholder='Username'
              name='username'
              onChange={e => setUsername(e.target.value)}
            />
          </div>
          <br />
          <div className='form-input'>
            <label>Password</label>
            <br />
            <input
              className='textarea'
              type='password'
              placeholder='Password'
              name='password'
              onChange={e => setPassword(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className='osel-modal-dialogue-buttons'>
        <button className='osel-button osel-primary-button' onClick={login}>
          Login
        </button>
      </div>
    </div>
  )
}

export default Login
