import React from 'react'

import { toggleUserProfileMenu } from './helpers/osel'

import './navbar.css'

export default function Navbar (props) {
  const { changeMapLocation, deleteToken, username } = props

  const logout = async () => {
    deleteToken()
  }

  return (
    <>
      <div className='header'>
        <div className='osel-title'>Secure data sharing</div>
        <div className='osel-navbar-items'>
          <div>
            <button
              className='osel-button osel-ghost-button margin-right'
              onClick={() => changeMapLocation(0.033010, 52.112346, 14)}
            >
              Shepreth
            </button>
            <button
              className='osel-button osel-ghost-button margin-right'
              onClick={() => changeMapLocation(-1.790734, 53.838241, 16)}
            >
              Saltaire
            </button>
          </div>
          <div className='user-profile' onClick={toggleUserProfileMenu}>
            <div className='avatar'></div>
            <span>{username}</span>
            <i className='material-icons'>keyboard_arrow_down</i>
          </div>
          <div className='line'></div>
        </div>
        <div className='osel-logo'>
          <img src='../assets/img/os-logo.svg' alt='Ordnance Survey logo' />
        </div>
        <div className='osel-more-dropmenu-btn'>
          <i className='material-icons' aria-label=''>
            more_horiz
          </i>
        </div>
      </div>
      <div className='osel-user-profile-menu hidden'>
        <div className='label' onClick={logout}>
          Sign out
        </div>
      </div>
      <div className='osel-more-dropmenu hidden'>
        <div className='title'>OSEL Framework</div>
        <hr />
        <div className='label user-profile'>
          <div className='avatar'></div>
          <span>{username}</span>
        </div>
        <div className='label interactive' onClick={logout}>
          Sign out
        </div>
        <hr />
        <div className='label logo'>
          <img src='../assets/img/os-logo.svg' alt='Ordnance Survey logo' />
        </div>
      </div>
    </>
  )
}
