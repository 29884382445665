import MapboxDraw from '@mapbox/mapbox-gl-draw'

import * as MapboxDrawGeodesic from 'mapbox-gl-draw-geodesic'

import { useControl } from 'react-map-gl/maplibre'

let modes = MapboxDraw.modes
modes = MapboxDrawGeodesic.enable(modes)

export let drawRef = null
export default function DrawControl (props) {
  drawRef = useControl(
    () => new MapboxDraw({ modes }),
    ({ map }) => {
      map.on('draw.create', props.onCreate)
      map.on('draw.update', props.onUpdate)
      map.on('draw.delete', props.onDelete)
    },
    ({ map }) => {
      map.off('draw.create', props.onCreate)
      map.off('draw.update', props.onUpdate)
      map.off('draw.delete', props.onDelete)
    },
    {
      position: props.position
    }
  )
  return null
}
