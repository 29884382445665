export const getCurrentLayerIds = () => {
  let _idArray = []
  window
    .$(
      ".osel-sliding-side-panel.panel-left .layer .layer-element[data-type='checkbox'][data-state!='unchecked']:not(.layer-options .layer-element)"
    )
    .each(function () {
      _idArray.push(window.$(this).parent().data('layer-id'))
    })

  return _idArray
}

export const openRightPanel = () => {
  window.sliderRight.slideReveal('show')
}

export const closeRightPanel = () => {
  window.sliderRight.slideReveal('hide')
}

export const addToggleLayerFunction = toggleFunc => {
  window.toggleLayer = toggleFunc
}

export const addFilterLayerFunction = filterFunc => {
  window.filterLayer = filterFunc
}

export const scrollSidePanelUp = () => {
  window
    .$(".osel-sliding-side-panel.panel-right [class^='scroller']")
    .scrollTop(0)
    .scrollLeft(0)
}

export const toggleUserProfileMenu = () => {
  window.$('.osel-user-profile-menu').toggleClass('hidden')
  window
    .$('.osel-user-profile-menu')
    .find('i')
    .toggleText('keyboard_arrow_up', 'keyboard_arrow_down')
}
