import { getToken } from './../models/user'

const config = require('./../config.json')

export function clipGeometries (clip, json) {
  const turf = window.turf
  for (let i in json.features) {
    if (json.features[i].properties && json.features[i].id)
      json.features[i].properties.id = json.features[i].id
    var geomType = json.features[i].geometry.type
    if (geomType === 'Point') {
      var point = turf.point(json.features[i].geometry.coordinates)
      if (!turf.booleanWithin(point, clip)) {
        json.features[i].geometry = null
        continue
      }
    } else if (geomType === 'LineString' || geomType === 'MultiLineString') {
      var line =
        geomType === 'LineString'
          ? turf.lineString(json.features[i].geometry.coordinates)
          : turf.multiLineString(json.features[i].geometry.coordinates)

      if (geomType === 'LineString') {
        if (
          !turf.booleanWithin(line, clip) &&
          turf.booleanDisjoint(line, clip)
        ) {
          json.features[i].geometry = null
          continue
        }

        var split = turf.lineSplit(line, clip)
        if (split.features.length) {
          let _lineArray = []
          turf.geomEach(split, function (geom) {
            if (turf.booleanContains(clip, geom))
              _lineArray.push(geom.coordinates)
          })
          json.features[i].geometry = turf.multiLineString(_lineArray).geometry
        }
      } else if (geomType === 'MultiLineString') {
        let _lineArray = []
        line.geometry.coordinates.forEach(function (coords) {
          var _line = turf.lineString(coords)
          if (
            turf.booleanWithin(_line, clip) ||
            !turf.booleanDisjoint(_line, clip)
          ) {
            var split = turf.lineSplit(_line, clip)
            if (split.features.length) {
              turf.geomEach(split, function (geom) {
                if (turf.booleanContains(clip, geom))
                  _lineArray.push(geom.coordinates)
              })
            }
          }
          json.features[i].geometry = turf.multiLineString(_lineArray).geometry
        })

        if (!_lineArray.length) {
          json.features[i].geometry = null
          continue
        }
      }
    } else if (geomType === 'Polygon') {
      var poly = turf.rewind(
        turf.polygon(json.features[i].geometry.coordinates)
      )
      if (turf.booleanDisjoint(poly, clip)) {
        json.features[i].geometry = null
        continue
      }

      // TODO: Handler for self-intersecting polygons
      var kinks = turf.kinks(poly)
      if (kinks.features.length) {
        json.features[i].geometry = null
        continue
      }

      var intersection = turf.intersect(poly, clip)
      if (intersection) json.features[i].geometry = intersection.geometry
    }
  }

  return json
}

export async function getMapData (polygon) {
  let mapData = {}

  const dataSources = Object.keys(config.dataSources)

  for (let i = 0; i < dataSources.length; i++) {
    const key = dataSources[i]
    const source = config.dataSources[key]

    if (polygon && polygon.geometry && polygon.geometry.coordinates) {
      const bbox = window.turf.bbox(polygon)

      try {
        // prettier-ignore
        const response = await fetch(`${source.url}&bbox=${bbox.join(',')}`, {
            headers: { "X-API-Key": `${getToken()}` }
          })
        const data = await response.json()
        mapData[key] = clipGeometries(polygon, data)
      } catch (error) {
        console.error(error)
      }
    }
  }
  return mapData
}

export const getMaskFromGeoJson = geojson => {
  const turf = window.turf
  const poly = turf.polygon(geojson.geometry.coordinates)
  const worldMask = turf.polygon([
    [
      [-180, -90],
      [180, -90],
      [180, 90],
      [-180, 90],
      [-180, -90]
    ]
  ])
  return turf.mask(poly, worldMask)
}
