import { getToken } from './user'

import { createGuid } from '../helpers/strings'

const config = require('../config')

export async function getFeatureObservations (id) {
  let data = null
  try {
    // prettier-ignore
    const response = await fetch(
        `${config.observation}/${id}`,
        {
            headers: {
            "X-API-Key": `${getToken()}`
            }
        }
        )
    data = await response.json()
  } catch {
    // Do nothing
  }

  return data
}

export async function addFeatureObservation (id, observation) {
  try {
    // prettier-ignore
    const response = await fetch(
        config.observation,
          {
            method: 'POST',
            headers: {
              "Content-Type": 'application/json',
              "X-API-Key": `${getToken()}`
            },
            body: JSON.stringify({
              assetid: id,
              description: observation,
              key: createGuid(),
              observationid: createGuid(),
              publisher: 'Test',
              status: 'Open',
              type: 'Observation',
              version: 1,
              x: 350001,
              y: 350001
            })
          }
        )
    await response.json()
  } catch {
    // Do nothing
  }
}
